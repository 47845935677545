import componentCert from "./componentCert.css";
import { Link } from "react-router-dom";
import Cert from "../../data/Cert.json";
import { p1 } from "../../data/Certj";
import { p2 } from "../../data/CertjSmall";
import ProjectJsonSmall from "../../data/ProjectJsonSmall.json";
import ComponentCertSingle from "./componentCertSingle";

function ComponentCert({ small }) {
  let ProjectMap = [];
  if (small) {
    ProjectMap = p2.map((project) => {
      return (
        <ComponentCertSingle
          key={project.id}
          src={project.src}
          tag={project.tag}
          title={project.title}
          description={project.description}
          btnTitle={project.btnTitle}
          btnTitleHref={project.btnTitleHref}
          btnTitle2={project.btnTitle2}
          btnTitleHref2={project.btnTitleHref2}
        />
      );
    });
  } else {
    ProjectMap = p1.map((project) => {
      return (
        <ComponentCertSingle
          key={project.id}
          src={project.src}
          tag={project.tag}
          title={project.title}
          description={project.description}
          btnTitle={project.btnTitle}
          btnTitleHref={project.btnTitleHref}
          btnTitle2={project.btnTitle2}
          btnTitleHref2={project.btnTitleHref2}
        />
      );
    });
  }

  return <div className="certTowDiv">{ProjectMap}</div>;
}

export default ComponentCert;
