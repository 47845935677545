import { useTranslation } from "react-i18next";

export function Direction() {
  const { _, i18n } = useTranslation();

  if (i18n.language === "fr") {
    document.body.style.direction = "ltr";
  } else if (i18n.language === "en") {
    document.body.style.direction = "ltr";
  }
  return i18n.language;
}
