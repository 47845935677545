import React, { useEffect } from "react";
import Typed from "typed.js";
import "./typeEffect.css";
import { useTranslation } from "react-i18next";

const TypingEffect = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Set up the options for the Typed instance
    const options = {
      strings: ["|"],
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000,
      loop: true,
    };

    // Initialize Typed.js
    const typed = new Typed(".text", options);

    // Cleanup on component unmount
    return () => {
      typed.destroy();
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return <span className="text" />;
};

export default TypingEffect;
