import React from "react";
import NavItem from "./NavItem";
import SelectLanguage from "./SelectLanguage";
import { useTranslation } from "react-i18next";
let data;

export default function MyAllNav() {
  const { t, i18n } = useTranslation();
  if (i18n.language === "fr") {
    data = [
      {
        id: 1,
        title: "Principale",
        href: "",
      },
      {
        id: 2,
        title: "certificat",
        href: "PageCERT",
      },
      {
        id: 3,
        title: "Projets",
        href: "PageProjects",
      },
      {
        id: 4,
        title: "Contacte moi",
        href: "contacts",
      },
      {
        id: 5,
        title: "blog",
        href: "blog",
      },
    ];
  } else {
    data = [
      {
        id: 1,
        title: "home",
        href: "",
      },
      {
        id: 2,
        title: "certificate",
        href: "PageCERT",
      },
      {
        id: 3,
        title: "Projects",
        href: "PageProjects",
      },
      {
        id: 4,
        title: "contacts",
        href: "contacts",
      },
      {
        id: 5,
        title: "blog",
        href: "blog",
      },
    ];
  }

  let dataMap = data.map((item, index) => {
    return (
      <NavItem id={item.id} key={index} href={item.href}>
        {item.title}
      </NavItem>
    );
  });

  return (
    <>
      <div className="NavAll">
        {dataMap}
        <SelectLanguage></SelectLanguage>
      </div>
    </>
  );
}
