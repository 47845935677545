import { useTranslation } from "react-i18next";
import PagePTitlePathname from "../../components/PagePTitlePathname/PagePTitlePathname";
import PageProjectsCSS from "./PageProjects.css";
import PageProjectsGroup from "./PageProjectsGroup";
import PageCertsGroup from "./PageCertsGroup ";

function PageCert() {
  const { t, i18n } = useTranslation();
  return (
    <div className="PageProjectsTOP">
      <PagePTitlePathname> </PagePTitlePathname>

      <PageCertsGroup h2={t("certF")} />
      <div className="small-projects">
        <PageCertsGroup small={true} h2={t("badges")} />
      </div>
    </div>
  );
}

export default PageCert;
