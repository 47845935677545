import { useTranslation } from "react-i18next";
import harvard from "../img/harvard.png";
import cc from "../img/cc.png";
import ccst from "../img/ccst.png";
import google from "../img/google.png";

const p1 = [
  {
    id: 3,
    src: cc,
    tag: ["ISC2"],
    title: "Certified in Cybersecurity (CC)",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://www.credly.com/earner/earned/badge/3ad2d188-4c85-466f-8d48-55d51bc011df",
  },
  {
    id: 5,
    src: ccst,
    tag: ["Cisco"],
    title: "Cisco Certified Support Technician (CCST) Networking",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://www.credly.com/earner/earned/badge/1543f242-9722-42e9-9b41-ef779af54c31",
  },
  {
    id: 1,
    src: harvard,
    tag: ["HarvardX"],
    title: "Introduction to Computer Science Certificate",
    description: "Welcome to our React-based E-commerce Store Project",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://certificates.cs50.io/f436b436-9404-44c7-bf09-58a74389761f.pdf?size=letter",
  },
  {
    id: 4,
    src: google,
    tag: ["Google"],
    title: "Google Cybersecurity Certificate",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://www.credly.com/badges/af13d6a4-3088-4b71-808e-9cfb9af2c79d",
  },
];

export { p1 };
