import { useTranslation } from "react-i18next";

import flix from "../img/flixify.png";
import far from "../img/far away.png";
import watch from "../img/watch2.png";
import PhotoSeek from "../img/photoseek.png";
import storyHub from "../img/storyHub.png";
import book from "../img/books.png";

const p2 = [
  {
    id: 3,
    src: flix,
    tag: ["Html", "Css", "Javascript"],
    title: "Flixify",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj-movies.netlify.app/",
  },
  {
    id: 5,
    src: far,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "Far away",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj6.netlify.app/",
  },
  {
    id: 1,
    src: watch,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "Custom Watches",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj5.netlify.app/",
  },
  {
    id: 10,
    src: PhotoSeek,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "PhotoSeek",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj4.netlify.app/",
  },
  {
    id: 11,
    src: storyHub,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "storyHub",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj3.netlify.app/",
  },
  {
    id: 12,
    src: book,
    tag: ["Html", "Css", "Javascript"],
    title: "Brooke Et Co.",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj2.netlify.app/",
  },
];

export { p2 };
