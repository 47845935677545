import ComponentProjectCss from "./ComponentProject.css";
import { Link } from "react-router-dom";
import ProjectJson from "../../../src/data/Projects.json";
import ProjectJsonSmall from "../../../src/data/ProjectJsonSmall.json";
import ComponentProjectSingle from "./ComponentProjectSingle";
import { p1 } from "../../data/proj";
import { p2 } from "../../data/projsmall";
import { p3 } from "../../data/projInt";

function ComponentProject({ small }) {
  let ProjectMap = [];
  if (small) {
    ProjectMap = p2.map((project) => {
      return (
        <ComponentProjectSingle
          key={project.id}
          src={project.src}
          tag={project.tag}
          title={project.title}
          btnTitle2={project.btnTitle2}
          btnTitleHref2={project.btnTitleHref2}
        />
      );
    });
  } else {
    ProjectMap = p3.map((project) => {
      return (
        <ComponentProjectSingle
          key={project.id}
          src={project.src}
          tag={project.tag}
          title={project.title}
          btnTitle2={project.btnTitle2}
          btnTitleHref2={project.btnTitleHref2}
        />
      );
    });
  }

  return <div className="ProjectsTowDiv">{ProjectMap}</div>;
}

export default ComponentProject;
