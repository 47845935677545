import React, { useEffect } from "react";
import NavItemMobile from "./NavItemMobile";
import SelectLanguageMobile from "./SelectLanguageMobile";
import MyAllNavMobilecss from "./MyAllNavMobile.css";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo";
import { ReactComponent as Close } from "../../.././assets/img/close.svg";
import { ReactComponent as Vector } from "../../.././assets/NavMobile/img/Vector.svg";
import { ReactComponent as Dribble } from "../../.././assets/NavMobile/img/Dribble.svg";
import { ReactComponent as Figma } from "../../.././assets/NavMobile/img/Figma.svg";
import { useTranslation } from "react-i18next";
import EmailVector from "../../../assets/Home/EmailVector.svg";
import linkedin from "../../../assets/PageContacts/linkedin.svg";

import thm from "../../../assets/Media/thm.svg";

let data;

export default function MyAllNavMobile({ menuOpen, toggleMenu }) {
  const { t, i18n } = useTranslation();
  if (i18n.language === "fr") {
    data = [
      {
        id: 1,
        title: "Principale",
        href: "",
      },
      {
        id: 2,
        title: "certificat",
        href: "PageCERT",
      },
      {
        id: 3,
        title: "Projets",
        href: "PageProjects",
      },
      {
        id: 4,
        title: "Contacte moi",
        href: "contacts",
      },
      {
        id: 5,
        title: "blog",
        href: "blog",
      },
    ];
  } else {
    data = [
      {
        id: 1,
        title: "home",
        href: "",
      },
      {
        id: 2,
        title: "certificate",
        href: "PageCERT",
      },
      {
        id: 3,
        title: "Projects",
        href: "PageProjects",
      },
      {
        id: 4,
        title: "contacts",
        href: "contacts",
      },
      {
        id: 5,
        title: "blog",
        href: "blog",
      },
    ];
  }

  let dataMap = data.map((item, index) => {
    return (
      <NavItemMobile id={item.id} key={index} href={item.href}>
        {item.title}
      </NavItemMobile>
    );
  });

  useEffect(() => {
    if (menuOpen === true) {
      document.querySelector("body").classList.toggle("overflow");
    } else {
      document.querySelector("body").classList.remove("overflow");
    }
  }, [menuOpen]);
  if (menuOpen) {
    return (
      <div className="NavAllMobileDiv">
        <div className="HeadNvMobile90">
          <div className="HeadNvMobile">
            <Logo />
            <div className="Headeclose" onClick={toggleMenu}>
              <Close />
            </div>
          </div>
          <div
            className={
              i18n.language === "fr" ? "NavAllMobile-AR" : "NavAllMobile"
            }
          >
            {dataMap}
            <SelectLanguageMobile></SelectLanguageMobile>
          </div>
        </div>
        <div className="SocialMobileDIV">
          <div className="SocialMobile">
            {/* <Link
              target="_blank"
              to={"https://www.linkedin.com/in/hassan-aswad-52031a1b1/"}
            >
              <Vector />
            </Link> */}
            {/* <div className="MycontactContentMedia-media">
              <img src={EmailVector} alt=""></img>
              <span>hassanaswad15@yahoo.ca</span>
            </div> */}
            <Link
              target="_blank"
              to={"https://www.linkedin.com/in/hassan-aswad-52031a1b1/"}
              className="PageContacts-Twitter"
            >
              {" "}
              <img src={linkedin} alt="linkedin"></img>
            </Link>
            <Link target="_blank" to={"https://leetcode.com/u/hassanaswad/"}>
              <img
                src="https://img.icons8.com/?size=100&id=On4zzIgLaBAu&format=png&color=000000"
                alt="LeetCode"
                className="leetcode"
              ></img>
            </Link>

            <Link target="_blank" to={"https://tryhackme.com/p/HassanAswad"}>
              <img src={thm} alt="Linkedin"></img>
            </Link>
            {/* <Dribble /> */}
            {/* <Figma /> */}
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="NavAllMobileDivOut"></div>;
  }
}
