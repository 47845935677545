import map from "../img/map3.png";
import pizaa from "../img/pizza.png";

import FitLifeFuel from "../img/fit.png";

const p3 = [
  {
    id: 11,
    src: FitLifeFuel,
    tag: ["Html", "Css", "Javascript"],
    title: "FitLifeFuel",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj1.netlify.app/",
  },
  {
    id: 1,
    src: pizaa,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "Fast Pizza",
    btnTitle2: "=>",
    btnTitleHref2: "https://pizzaa-store.netlify.app/",
  },
  {
    id: 5,
    src: map,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "WorldWise",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj9.netlify.app/",
  },
];

export { p3 };
