import { Link } from "react-router-dom";
import MyFooterCss from "./MyFooter.css";
import MyFooterLeft from "./MyFooterLeft";
import MyFooterRight from "./MyFooterRight";
import { useTranslation } from "react-i18next";
function MyFooter() {
  const { t, i18n } = useTranslation();

  return (
    <div className="MyFooterTOP">
      <div className="MyFooter">
        <MyFooterLeft />
        <MyFooterRight />
      </div>

      <div className="Copyright">
        {t("Copyright")} © {new Date().getFullYear()}.
        <Link to={"https://www.linkedin.com/in/hassan-aswad-52031a1b1/"}>
          Hassan Aswad
        </Link>
      </div>
    </div>
  );
}

export default MyFooter;
