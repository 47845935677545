import React from "react";
import MyFooterRightCss from "./MyFooterRight.css";
import { Link } from "react-router-dom";
import Github from "../../assets/Footer/Github.svg";
import Tiwiter from "../../assets/Media/Twitter.svg";
import linkedin from "../../assets/Media/Linkedin.svg";
import Discord from "../../assets/Footer/Discord.svg";
import { useTranslation } from "react-i18next";
import thm from "../../assets/Media/thm.svg";

function MyFooterRight() {
  const { t, i18n } = useTranslation();

  return (
    <div className="MyFooterRight">
      <span className={i18n.language === "fr" ? "MyFooterRightSpan-AR" : ""}>
        {t("Media")}
      </span>
      <div id="rightMidea" className="MyFooterRightMedia">
        <Link target="_blank" to={"https://tryhackme.com/p/HassanAswad"}>
          <img src={thm} alt="Linkedin"></img>
        </Link>

        <Link target="_blank" to={"https://leetcode.com/u/hassanaswad/"}>
          <img
            src="https://img.icons8.com/?size=100&id=On4zzIgLaBAu&format=png&color=000000"
            alt="LeetCode"
            className="leetcode"
          ></img>
        </Link>
        <Link to={"https://www.linkedin.com/in/hassan-aswad-52031a1b1/"}>
          {" "}
          <img src={linkedin} alt="Discord"></img>
        </Link>

        {/* <Link to={"https://github.com/"}>
          {" "}
          <img src={Github} alt="Github"></img>
        </Link>
        <Link to={"https://twitter.com/"}>
          {" "}
          <img src={Tiwiter} alt="Figma"></img>
        </Link> */}
      </div>
    </div>
  );
}

export default MyFooterRight;
