import { useTranslation } from "react-i18next";
import SkillsSectionTowCss from "./SkillsSectionTow.css";
import SkillsSectionTowTag from "./SkillsSectionTowTag";
function SkillsSectionTow() {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={
        i18n.language === "fr"
          ? "SkillsSectionTow SkillsSectionTow-AR"
          : "SkillsSectionTow"
      }
      data-aos="fade-up"
    >
      <div className="SkillsSectionTow-Languages">
        {/* tag */}
        <SkillsSectionTowTag h5={t("Languages")}>
          <span>PYTHON</span> <span>JAVASCRIPT</span>
          <span>JAVA</span>
          <span>PHP</span>
        </SkillsSectionTowTag>
        {/* ==tag== */}
      </div>

      <div className="SkillsSectionTow-Databases">
        {/* tag */}
        <SkillsSectionTowTag h5={t("Databases")}>
          <span>MySQL</span>
          <span>MongoDB</span>
          <span>MariaDB</span>
        </SkillsSectionTowTag>
        {/* ==tag== */}
      </div>
      <div className="SkillsSectionTow-Other">
        {/* tag */}
        <SkillsSectionTowTag h5={t("Other")}>
          <span>Security Principles</span>
          <span>Risk Management</span>
          <span>Incident Response</span>
          <span>Network Security</span>
          <span>Security Operations</span>
          <span>Log Analysis</span>
          <span>Cryptography</span>
          <span>Access Control</span>
          <span>Endpoint Security</span>
          <span>Vulnerability Management</span>
          <span>MITRE ATT&CK</span>
          <span>PCI-DSS</span>
          <span>CIS Controls</span>
          <span>NIST CSF</span>
          <span>ISO 27001</span>
          <span>NIST and GDPR</span>
        </SkillsSectionTowTag>
        {/* ==tag== */}
      </div>
      <div className="SkillsSectionTow-Tools">
        {/* tag */}
        <SkillsSectionTowTag h5={t("Tools")}>
          <span>OSI Model</span>
          <span>
            TCP/IP Protocols, DNS, DHCP, and other fundamental networking
            protocols
          </span>
          <span>Firewalls, (IDS/IPS), VPNs, Wireless</span>
          <span>Wireshark</span>
          <span>Subnets and VLANs</span>
          <span>Network Security</span>
          <span>Network Troubleshooting and Configuration</span>
          <span>Network Monitoring and Management</span>
        </SkillsSectionTowTag>
        {/* ==tag== */}
      </div>

      <div className="SkillsSectionTow-Frameworks">
        {" "}
        {/* tag */}
        <SkillsSectionTowTag h5={t("Frameworks")}>
          <span>Scripting and Automation</span>
          <span>Windows, Linux, and Unix operating systems</span>
          <span>Communication Skills</span>
          <span>Problem-Solving</span>
        </SkillsSectionTowTag>
        {/* ==tag== */}
      </div>
    </div>
  );
}

export default SkillsSectionTow;
