import React, { useState, useEffect } from "react";
import blogPostsData from "./data";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import blog from "./img/blog.jpg";

// Blog post component
const BlogPost = ({ title, image, body, category, onClick }) => (
  <div
    className="bg-gray-900 hover:bg-gray-800 transition-all duration-300 text-white mb-6 rounded-lg overflow-hidden shadow-lg cursor-pointer"
    onClick={onClick}
  >
    <img
      src={image}
      alt={title}
      className="w-full h-48 object-cover hover:scale-105 transition-transform duration-300"
    />
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-2 text-purple-400">{title}</h2>
      <p className="text-gray-400 mb-4">{body.substring(0, 150)}...</p>
      <p className="text-sm text-gray-500 italic">{category}</p>
    </div>
  </div>
);

// Single post view component with styled content
const BlogPostContent = ({ post, onBack }) => (
  <div className="text-white">
    <button
      onClick={onBack}
      className="mb-4 px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-all duration-300"
    >
      Back to Posts
    </button>
    <img
      src={post.image}
      alt={post.title}
      className="w-full h-64 object-cover mb-4 rounded-lg shadow-lg"
    />
    <h2 className="text-4xl font-bold mb-4 text-purple-400">{post.title}</h2>
    <div
      className="post-content prose prose-invert lg:prose-xl mb-8 text-gray-300"
      dangerouslySetInnerHTML={{ __html: post.content }}
    />
    <button
      onClick={onBack}
      className="mb-20 mt-4 px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-all duration-300"
    >
      Back to Posts
    </button>
  </div>
);

// Main blog component
const ProfessionalBlog = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;
  const [selectedPost, setSelectedPost] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation(); // Track URL changes

  useEffect(() => {
    setPosts(blogPostsData);
    setFilteredPosts(blogPostsData);
  }, []);

  // This effect updates the selected post based on the URL ID.
  useEffect(() => {
    if (id) {
      const post = blogPostsData.find((post) => post.id === parseInt(id));
      if (post) {
        setSelectedPost(post);
      }
    } else {
      setSelectedPost(null); // Reset to blog list when there's no post ID
    }
  }, [id]);

  const categories = ["All", ...new Set(posts.map((post) => post.category))];

  const filterPosts = (category) => {
    setSelectedCategory(category);
    if (category === "All") {
      setFilteredPosts(posts);
    } else {
      setFilteredPosts(posts.filter((post) => post.category === category));
    }
    setCurrentPage(1);
  };

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Navigate to post detail page by ID
  const handlePostClick = (post) => {
    navigate(`/blog/${post.id}`);
  };

  return (
    <div className="container mx-auto px-4 py-12 text-white">
      {selectedPost ? (
        <BlogPostContent
          post={selectedPost}
          onBack={() => {
            setSelectedPost(null); // Clear selected post
            navigate("/blog"); // Navigate back to blog list
          }}
        />
      ) : (
        <>
          <header className="mb-12">
            <h1 className="text-5xl font-extrabold mb-6 text-purple-400">
              My Blog
            </h1>
          </header>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
            {/* Categories Section - Above Blog Posts on Mobile */}
            <aside className="w-full md:hidden bg-gray-800 p-6 rounded-lg shadow-lg">
              <h2 className="font-bold text-xl mb-6 text-purple-400">
                Categories
              </h2>
              <div className="flex flex-col space-y-3">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => filterPosts(category)}
                    className={`px-4 py-2 rounded-lg ${
                      selectedCategory === category
                        ? "bg-purple-600 text-white"
                        : "bg-gray-700 text-gray-300"
                    } hover:bg-gray-600 transition-all duration-300`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </aside>

            {/* Blog Posts Section */}
            <main className="col-span-3">
              {currentPosts.map((post) => (
                <BlogPost
                  key={post.id}
                  {...post}
                  onClick={() => handlePostClick(post)}
                />
              ))}
              <div className="flex justify-center mt-8 mb-20 space-x-2">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`px-4 py-2 mx-1 rounded-lg ${
                      currentPage === index + 1
                        ? "bg-purple-600 text-white"
                        : "bg-gray-700 text-gray-300"
                    } hover:bg-gray-500 transition-all duration-300`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </main>

            {/* Categories Section - Only visible on desktop */}
            <aside className="hidden md:block md:col-span-1">
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg ">
                <h2 className="font-bold text-xl mb-6 text-purple-400">
                  Categories
                </h2>
                <div className="flex flex-col space-y-3">
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => filterPosts(category)}
                      className={`px-4 py-2 rounded-lg ${
                        selectedCategory === category
                          ? "bg-purple-600 text-white"
                          : "bg-gray-700 text-gray-300"
                      } hover:bg-gray-600 transition-all duration-300`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfessionalBlog;
