import { useTranslation } from "react-i18next";
import jp from "../img/jp.png";
import aws from "../img/aws.png";
import path from "../img/ciscoPath.png";
import endpoit from "../img/endpoint.png";
import netdev from "../img/netDev.png";
import isc2 from "../img/isc2.png";

const p2 = [
  {
    id: 3,
    src: jp,
    tag: ["JPMorgan"],
    title: "Software Engineering Job Simulation",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/R5iK7HMxJGBgaSbvk_J.P.%20Morgan_BNA8Xy8Fb5Tzqdxkc_1713645419501_completion_certificate.pdf",
  },
  {
    id: 5,
    src: aws,
    tag: ["AWS"],
    title: "AWS Solutions Architecture Job Simulation",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/AWS/kkE9HyeNcw6rwCRGw_AWS%20APAC_BNA8Xy8Fb5Tzqdxkc_1713651684439_completion_certificate.pdf",
  },
  {
    id: 1,
    src: path,
    tag: ["Cisco"],
    title: "Junior Cybersecurity Analyst",
    description: "Welcome to our React-based E-commerce Store Project",
  },
  {
    id: 4,
    src: endpoit,
    tag: ["Cisco"],
    title: "Endpoint Security",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://www.credly.com/badges/76cb2540-6ea2-465f-a37c-3ae99e586f60/public_url",
  },
  {
    id: 10,
    src: netdev,
    tag: ["Cisco"],
    title: "Networking Devices and Configuration",
    btnTitle2: "=>",
    btnTitleHref2:
      "https://www.credly.com/badges/a12a5bc2-d151-49fc-b437-c25b63be61cb/public_url",
  },
  {
    id: 11,
    src: isc2,
    tag: ["ISC2"],
    title: "ISC2 Candidate",
  },
];

export { p2 };
