import { useTranslation } from "react-i18next";
import aqua from "../img/aqua.png";
import map from "../img/map3.png";
import pizaa from "../img/pizza.png";
import use from "../img/usepop.png";
import quiz from "../img/quiz.png";
import FitLifeFuel from "../img/fit.png";

const p1 = [
  {
    id: 11,
    src: FitLifeFuel,
    tag: ["Html", "Css", "Javascript"],
    title: "FitLifeFuel",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj1.netlify.app/",
  },
  {
    id: 1,
    src: pizaa,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "Fast Pizza",
    btnTitle2: "=>",
    btnTitleHref2: "https://pizzaa-store.netlify.app/",
  },
  {
    id: 5,
    src: map,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "WorldWise",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj9.netlify.app/",
  },

  {
    id: 4,
    src: use,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "UsePopcorn",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj7.netlify.app/",
  },
  {
    id: 10,
    src: quiz,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "The React Quiz",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-proj8.netlify.app/",
  },
  {
    id: 3,
    src: aqua,
    tag: ["Html", "Css", "Javascript", "React"],
    title: "AQUAVEN",
    btnTitle2: "=>",
    btnTitleHref2: "https://hassan-fcompany.netlify.app/",
  },
];

export { p1 };
