import React, { useEffect } from "react";
import ComponentProject from "../../components/componentProject/ComponentProject";
import ComponentCert from "../../components/componentCert/componentCert";
import Aos from "aos";
import { use } from "i18next";
function PageCertsGroup({ h2, small }) {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="PageProjectsComplete">
      <div className="PageProjectsCompleteOne">
        <span>#</span>
        <h2>{h2}</h2>
      </div>

      <div data-aos="fade-up">
        <ComponentCert small={small} />
      </div>
    </div>
  );
}

export default PageCertsGroup;
